var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: _vm.textClasses,
      attrs: { tabindex: "0", href: "#" },
      on: {
        mouseover: function ($event) {
          _vm.active = true
        },
        mouseout: function ($event) {
          _vm.active = false
        },
      },
    },
    [
      _vm._t(
        "suggestion",
        function () {
          return [
            _vm.data.type == "geo"
              ? _c(
                  "span",
                  { staticClass: "vbst-icon vbst-icon-geo" },
                  [_c("SvgAutGeo")],
                  1
                )
              : _vm.data.type == "property"
              ? _c(
                  "span",
                  { staticClass: "vbst-icon vbst-icon-property" },
                  [_c("SvgAutProperty")],
                  1
                )
              : _vm.data.type == "inSalabam"
              ? _c(
                  "span",
                  { staticClass: "vbst-icon vbst-icon-insalabam" },
                  [_c("SvgAutInSalabam")],
                  1
                )
              : _vm.data.type == "msg"
              ? _c(
                  "span",
                  { staticClass: "vbst-icon vbst-icon-not-results" },
                  [_c("SvgAutNotResults")],
                  1
                )
              : _c(
                  "span",
                  { staticClass: "vbst-icon vbst-icon-here" },
                  [_c("SvgAutHere")],
                  1
                ),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.htmlText) } }),
          ]
        },
        null,
        { data: _vm.data, htmlText: _vm.htmlText }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }