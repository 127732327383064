var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", {
      staticClass: "copyScadenza",
      domProps: { innerHTML: _vm._s(_vm.copyScadenza) },
    }),
    _vm.extra
      ? _c("span", {
          staticClass: "copyScadenza",
          domProps: { innerHTML: _vm._s(_vm.extra) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }