var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        width: "512",
        height: "512",
        viewBox: "0 0 512 512",
        "enable-background": "new 0 0 512 512",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("g", [
          _c("g", [
            _c("path", {
              attrs: {
                fill: "#2e3645",
                d: "m343 300l-44 45 167 167 44-44z m-133-244c84 0 153 69 153 155 0 85-69 154-153 154-84 0-152-69-152-155 0-85 68-154 152-154m0-56c-114 0-208 94-208 211 0 116 93 211 208 211 116 0 209-95 209-211 0-117-93-211-209-211z",
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }