var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "filters" } },
    [
      !_vm.loading
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.modal-filters",
                  modifiers: { "modal-filters": true },
                },
              ],
              staticClass: "btn-with-icon",
              attrs: { variant: "light" },
            },
            [
              _c(
                "div",
                { staticClass: "btn-with-icon-inner" },
                [
                  _c("SvgSettings"),
                  _c("span", [_vm._v("Filtra")]),
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "10",
                        viewBox: "0 0 18 10",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M16.2635 1.21558L8.83 8.64915L1.39648 1.21558",
                          stroke: "#333333",
                          "stroke-width": "2.25033",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-filters",
            size: "lg",
            title: "Filtra i risultati",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("Chiudi")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-form-group",
            { staticClass: "mt-4" },
            [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _vm._l(_vm.amenities, function (amenity) {
                        return _c(
                          "b-col",
                          {
                            key: amenity.label,
                            class: "col-" + amenity.label,
                            attrs: { cols: "12", lg: "3" },
                          },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("p", { staticClass: "filterLabel" }, [
                                  amenity.label == "Property"
                                    ? _c("span", [_vm._v("Caratteristiche")])
                                    : _vm._e(),
                                  amenity.label == "Rate"
                                    ? _c("span", [_vm._v("Servizi inclusi")])
                                    : _vm._e(),
                                  amenity.label == "Refundable"
                                    ? _c("span", [_vm._v("Rimborsabile")])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "b-form-checkbox-group",
                                  {
                                    attrs: {
                                      id: "amenities-" + amenity.label,
                                      name: amenity.label,
                                      stacked: "",
                                    },
                                    model: {
                                      value: _vm.selectedAmenities,
                                      callback: function ($$v) {
                                        _vm.selectedAmenities = $$v
                                      },
                                      expression: "selectedAmenities",
                                    },
                                  },
                                  _vm._l(amenity.filters, function (f) {
                                    return _c(
                                      "b-form-checkbox",
                                      {
                                        key: f.label,
                                        attrs: { value: f.value },
                                        on: { change: _vm.filterChange },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(f.text) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "b-col",
                        { class: "col-Type", attrs: { cols: "12", lg: "3" } },
                        [
                          _c(
                            "b-form-checkbox-group",
                            {
                              attrs: {
                                id: "categories",
                                name: "categories",
                                stacked: "",
                              },
                              model: {
                                value: _vm.selectedCategories,
                                callback: function ($$v) {
                                  _vm.selectedCategories = $$v
                                },
                                expression: "selectedCategories",
                              },
                            },
                            [
                              _c("p", { staticClass: "filterLabel" }, [
                                _vm._v("Tipo di struttura"),
                              ]),
                              _vm._l(
                                _vm.categories,
                                function (category, index) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: index,
                                      attrs: { value: index },
                                      on: { change: _vm.filterChange },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(category) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }