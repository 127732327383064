<template>
    <div class="nightsSelect">
        <div class="title">
            <font-awesome-icon icon="moon"/>
            {{nights == 1 ? 'Notte' : 'Notti'}}
        </div>
        <div class="nightsSelectInput">
            <div class="nightsSelectInput--remove"><b-button @click="remove()" size="sm">-</b-button></div>
            <div class="nightsSelectInput--input">
                <div class="number"><VNumber v-model="nights" :speed="250" /></div>
            </div>
            <div class="nightsSelectInput--add"><b-button @click="add()" size="sm">+</b-button></div>
        </div>
    </div>
</template>

<script>
import { VNumber } from '@maxflex/v-number'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMoon } from '@fortawesome/free-solid-svg-icons'
library.add(faMoon)


export default
{
    name: 'NightsSelect',
    components:
    {
        VNumber
    },
    props:
    {
        nights: Number,
        options: Array,
    },
    data()
    {
        return {
            n: 0
        }
    },
    mounted()
    {
    },
    methods: {
        getOptionIndex(nights)
        {
            let pos = this.options.findIndex((n) => {
                return n.value === nights
            })
            return pos
        },
        add()
        {
            let pos = this.getOptionIndex(this.nights)
            if (pos == this.options.length-1) return
            this.$emit('change', this.options[pos+1].value)
        },
        remove()
        {
            let pos = this.getOptionIndex(this.nights)
            if (pos == 1) return
            this.$emit('change', this.options[pos-1].value)
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .nightsSelect
    {
        display: flex;
        .title
        {
            //padding-left: 2px;
            //padding-right: 20px;
            height:inherit;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction:column;
            font-size: 14px;

            width:55px;
            min-width:55px;
            font-size: 11px;
            color:$primary;
            margin-right: 18px;
            position:relative;
            //font-weight:700;
            &:before
            {
                content:"";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 20px;
                width: 1px;
                background: #cccccc;
            }
        }
    }



    .nightsSelectInput
    {
        width:100%;
        display: flex;
        height:48px;
        box-shadow: $box-shadow;
        &--input
        {
            flex: 1;
            align-items: center;
            justify-content: center;
            display: flex;
            background: $white;
            position:relative;
            .number
            {
                position:absolute;
                width:50%;
                height:100%;
                left:0;
                top:0;
                display:flex;
                align-items: center;
                padding-right:5px;
            }
            .copy
            {
                position:absolute;
                width:50%;
                height:100%;
                right:0;
                top:0;
                border:1px solid green;
            }
        }
        &--add
        {
            .btn
            {
                border-top-left-radius:0;
                border-bottom-left-radius:0;
            }
        }
        &--remove
        {
            .btn
            {
                border-top-right-radius:0;
                border-bottom-right-radius:0;
            }
        }
        .btn
        {
            box-shadow: none !important;
            outline: none !important;
            display: flex;
            align-items: center;
            justify-content: center;
            height:100%;
            font-size:30px !important;
            font-weight: normal;
            line-height: 1;
            background:$secondary;
            border-color:$secondary;
            min-width: 44px;
        }
        .v-number
        {
            display: flex !important;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            font-size:14px;
        }
    }
</style>