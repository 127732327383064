var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "children" } },
    [
      _c(
        "b-dropdown",
        {
          ref: "dropdownOccupancy",
          attrs: {
            variant: "transparent",
            id: "dropdownOccupancy",
            text: _vm.children.length.toString(),
            "data-e2e": "searchFormOccupancyMenu",
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c("div", [
                    _vm._v("\n            " + _vm._s(_vm.adults) + " "),
                    _vm.adults == 1
                      ? _c("span", [_vm._v("Adulto")])
                      : _c("span", [_vm._v("Adulti")]),
                    _vm._v(
                      "\n            -\n            " +
                        _vm._s(_vm.children.length) +
                        " "
                    ),
                    _vm.children.length == 1
                      ? _c("span", [_vm._v("Bambino")])
                      : _c("span", [_vm._v("Bambini")]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-dropdown-form",
            [
              _c(
                "b-form-group",
                {
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "occupancy-items" }, [
                    _c("div", { staticClass: "occupancy-item" }, [
                      _c("div", [
                        _vm._v(
                          "\n                            Adulti\n                        "
                        ),
                      ]),
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "span",
                          {
                            staticClass: "square-icon",
                            attrs: {
                              "data-e2e": "searchFormOccupancyRemoveAdults",
                            },
                            on: { click: _vm.removeAdult },
                          },
                          [_vm._v("-")]
                        ),
                        _c("strong", [_vm._v(_vm._s(_vm.adults))]),
                        _c(
                          "span",
                          {
                            staticClass: "square-icon",
                            attrs: {
                              "data-e2e": "searchFormOccupancyAddAdults",
                            },
                            on: { click: _vm.addAdult },
                          },
                          [_vm._v("+")]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "occupancy-item" }, [
                      _c("div", [
                        _vm._v(
                          "\n                            Bambini\n                        "
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticClass: "square-icon",
                            on: { click: _vm.removeChild },
                          },
                          [_vm._v("-")]
                        ),
                        _c("strong", [_vm._v(_vm._s(_vm.children.length))]),
                        _c(
                          "span",
                          {
                            staticClass: "square-icon",
                            on: { click: _vm.addChild },
                          },
                          [_vm._v("+")]
                        ),
                      ]),
                    ]),
                    _vm.children.length > 0
                      ? _c("div", { staticClass: "occupancy-item" }, [
                          _c(
                            "div",
                            [
                              _c("strong", [
                                _vm._v(
                                  "Età dei bambini il giorno del check-in"
                                ),
                              ]),
                              _vm._l(_vm.children, function (m, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "occupancy-age" },
                                  [
                                    _c("span", { staticClass: "nowrap" }, [
                                      _vm._v("Bambino " + _vm._s(index + 1)),
                                    ]),
                                    _c("b-form-select", {
                                      attrs: { options: _vm.options, value: m },
                                      on: {
                                        change: function (value) {
                                          _vm.setAge(value, index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "occupancy-item closeButton" },
            [
              _c(
                "b-button",
                {
                  staticClass: "closeDropdown",
                  attrs: { "data-e2e": "searchFormOccupancyConfirm" },
                  on: {
                    click: function ($event) {
                      return _vm.dropdownClose()
                    },
                  },
                },
                [_vm._v("\n                Conferma\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }