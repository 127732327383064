var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "700pt",
        height: "700pt",
        version: "1.1",
        viewBox: "0 0 700 700",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "m414.96 340.48c26.879-32.48 53.199-75.039 53.199-117.6 0-64.961-52.641-117.6-117.6-117.6-64.961 0-117.6 52.641-117.6 117.6 0 42.559 25.762 84.559 52.641 117.6-52.641 7.2812-105.84 25.762-105.84 56.559 0 39.762 87.922 61.039 170.24 61.039 82.32 0 170.24-21.281 170.24-61.039-0.55469-30.238-53.754-48.719-105.27-56.559zm-15.68-115.92c0 26.879-21.84 49.281-49.281 49.281-26.879 0-49.281-21.84-49.281-49.281 0-26.879 21.84-49.281 49.281-49.281 27.441 0.003906 49.281 22.402 49.281 49.281zm-49.84 222.32c-94.078 0-159.04-26.32-159.04-49.84 0-19.602 42-38.641 102.48-46.48v-1.6797c26.32 30.238 51.52 50.398 53.762 52.078 1.1211 0.55859 2.2383 1.1211 3.3594 1.1211s2.2383-0.55859 3.3594-1.1211c2.2383-1.6797 27.441-21.84 53.762-52.078v1.6797c59.922 7.8398 101.36 26.879 101.36 46.48-0.003906 23.52-64.965 49.84-159.04 49.84z",
          },
        }),
        _c("use", { attrs: { x: "70", y: "644", "xlink:href": "#s" } }),
        _c("use", { attrs: { x: "90.550781", y: "644", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "104.359375", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "123.347656", y: "644", "xlink:href": "#k" } }),
        _c("use", { attrs: { x: "142.242188", y: "644", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "155.628906", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "174.617188", y: "644", "xlink:href": "#j" } }),
        _c("use", { attrs: { x: "204.410156", y: "644", "xlink:href": "#i" } }),
        _c("use", { attrs: { x: "224.453125", y: "644", "xlink:href": "#e" } }),
        _c("use", { attrs: { x: "252.453125", y: "644", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "265.835938", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "284.828125", y: "644", "xlink:href": "#h" } }),
        _c("use", { attrs: { x: "294.421875", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "313.414062", y: "644", "xlink:href": "#e" } }),
        _c("use", { attrs: { x: "331.664062", y: "644", "xlink:href": "#g" } }),
        _c("use", { attrs: { x: "360.84375", y: "644", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "380.078125", y: "644", "xlink:href": "#f" } }),
        _c("use", { attrs: { x: "70", y: "672", "xlink:href": "#r" } }),
        _c("use", { attrs: { x: "82.183594", y: "672", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "95.992188", y: "672", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "115.226562", y: "672", "xlink:href": "#g" } }),
        _c("use", { attrs: { x: "154.152344", y: "672", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "167.535156", y: "672", "xlink:href": "#q" } }),
        _c("use", { attrs: { x: "187.46875", y: "672", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "216.207031", y: "672", "xlink:href": "#p" } }),
        _c("use", { attrs: { x: "239.640625", y: "672", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "258.878906", y: "672", "xlink:href": "#o" } }),
        _c("use", { attrs: { x: "278.8125", y: "672", "xlink:href": "#f" } }),
        _c("use", { attrs: { x: "308.492188", y: "672", "xlink:href": "#n" } }),
        _c("use", { attrs: { x: "329.015625", y: "672", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "342.820312", y: "672", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "362.058594", y: "672", "xlink:href": "#m" } }),
        _c("use", { attrs: { x: "371.65625", y: "672", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "390.648438", y: "672", "xlink:href": "#l" } }),
        _c("use", { attrs: { x: "407.242188", y: "672", "xlink:href": "#b" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }