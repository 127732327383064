var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "700pt",
        height: "700pt",
        version: "1.1",
        viewBox: "0 0 700 700",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "m504 126c-30.461-30.477-69.277-51.234-111.54-59.648-42.262-8.4102-86.066-4.1055-125.88 12.379-39.809 16.488-73.836 44.406-97.777 80.234-23.941 35.824-36.723 77.945-36.723 121.04s12.781 85.211 36.723 121.04c23.941 35.828 57.969 63.746 97.777 80.234 39.812 16.484 83.617 20.789 125.88 12.379 42.258-8.4141 81.074-29.172 111.54-59.648 40.695-40.922 63.535-96.289 63.535-154s-22.84-113.08-63.535-154zm-338.24 154c-0.082031-47.004 17.805-92.266 50-126.51 32.195-34.25 76.266-54.898 123.19-57.719 46.922-2.8164 93.145 12.402 129.21 42.551l-259.84 259.84c-27.672-33.137-42.746-74.988-42.559-118.16zm314.72 130.48c-32.574 32.344-76.02 51.41-121.88 53.48-45.859 2.0703-90.848-13.004-126.2-42.281l259.28-259.84c29.438 35.395 44.605 80.5 42.535 126.49-2.0742 45.988-21.234 89.547-53.734 122.15z",
          },
        }),
        _c("use", { attrs: { x: "70", y: "644", "xlink:href": "#u" } }),
        _c("use", { attrs: { x: "90.550781", y: "644", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "104.359375", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "123.347656", y: "644", "xlink:href": "#j" } }),
        _c("use", { attrs: { x: "142.242188", y: "644", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "155.628906", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "174.617188", y: "644", "xlink:href": "#i" } }),
        _c("use", { attrs: { x: "204.410156", y: "644", "xlink:href": "#h" } }),
        _c("use", { attrs: { x: "224.453125", y: "644", "xlink:href": "#g" } }),
        _c("use", { attrs: { x: "252.453125", y: "644", "xlink:href": "#f" } }),
        _c("use", { attrs: { x: "274.121094", y: "644", "xlink:href": "#t" } }),
        _c("use", { attrs: { x: "299.988281", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "318.976562", y: "644", "xlink:href": "#s" } }),
        _c("use", { attrs: { x: "335.644531", y: "644", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "354.882812", y: "644", "xlink:href": "#e" } }),
        _c("use", { attrs: { x: "384.054688", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "70", y: "672", "xlink:href": "#r" } }),
        _c("use", { attrs: { x: "82.183594", y: "672", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "95.992188", y: "672", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "115.226562", y: "672", "xlink:href": "#e" } }),
        _c("use", { attrs: { x: "154.152344", y: "672", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "167.535156", y: "672", "xlink:href": "#q" } }),
        _c("use", { attrs: { x: "187.46875", y: "672", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "216.207031", y: "672", "xlink:href": "#p" } }),
        _c("use", { attrs: { x: "239.640625", y: "672", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "258.878906", y: "672", "xlink:href": "#o" } }),
        _c("use", { attrs: { x: "278.8125", y: "672", "xlink:href": "#n" } }),
        _c("use", { attrs: { x: "308.492188", y: "672", "xlink:href": "#m" } }),
        _c("use", { attrs: { x: "329.015625", y: "672", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "342.820312", y: "672", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "362.058594", y: "672", "xlink:href": "#l" } }),
        _c("use", { attrs: { x: "371.65625", y: "672", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "390.648438", y: "672", "xlink:href": "#k" } }),
        _c("use", { attrs: { x: "407.242188", y: "672", "xlink:href": "#c" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }