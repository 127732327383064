<template>
  <div>
    <span class="copyScadenza" v-html="copyScadenza"></span>
    <span v-if="extra" class="copyScadenza" v-html="extra"></span>
  </div>
</template>

<script>
import Vue from 'vue'

// renderizza automaticamente il copy relativo alla durata dei voucher
// in base alla "validità e promo"
// con possibilità di customizzare i copy tramite props e placeholders
// https://trello.com/c/vysJA840/882-copy-scadenza
export default {
  name: 'copy-scadenza',
  data(){
    return {

    }
  },
  props: {

    // copy scadenza default
    default: {
      type: String,
      default: 'I voucher Salabam hanno una validità di {value} mesi, questo significa che avrai tutto il tempo che vorrai per prenotare il tuo soggiorno.',
    },

    // copy per scadenza vouchers durante promo "numero mesi"
    promoMonths: {
      type: String,
      default: 'Promozione "{promoTitle}", se acquisti entro il {promoValidUntil}, avrai ben {value} mesi anzichè i tradizionali {defaultMonths} per decidere dove e quando viaggiare',
    },

    // copy per scadenza vouchers durante promo "a data fissa"
    promoFixed: {
      type: String,
      default: 'Speciale {promoTitle}, acquista adesso e prolungheremo la durata del tuo voucher fino al {value} (invece dei tradizionali {defaultMonths} mesi)',
    },

    // copy usato se nessuno dei casi precedenti
    backup: {
      type: String,
      default: 'Ogni Salabam è valido {defaultMonths} mesi dalla data di acquisto, salvo eventuali promozioni in corso', // copy backup, riferito a validità default + accenno possibilità promo (usato solo in caso di problemi)
    },

    extra: {
      type: String,
      default: '',
    },

    // per passare configurazione di test, default computed vouchersValidity()
    testVouchersValidity: {
      type: Object,
      default: () => { return {} }
    },

  },
  computed: {

    // @vuese
    // selezione del copy corretto in base a se promozione attiva e di che tipo
    copyScadenza: {
      get() {

        if(!this.vouchersValidity.isPromo) return this.parsePlaceholders(this.default)
        if(this.vouchersValidity.type == 'months') return this.parsePlaceholders(this.promoMonths)
        if(this.vouchersValidity.type == 'fixed') return this.parsePlaceholders(this.promoFixed)

        return this.parsePlaceholders(this.backup)

      },
      set() {},
    },

    // @vuese
    // se non viene specificata la prop testVoucherValidity, uso la .config
    vouchersValidity(){

      if(Object.keys(this.testVouchersValidity).length !== 0){
        return this.testVouchersValidity
      }

      return Vue.prototype.$config.guiSettings.vouchersValidity // usati anche per le sostituzioni
    },

  },
  methods:{

    // @vuese
    // usa vouchersValidity() per la sostituzione dei {placeholder}
    // @arg il copy con {placeholder} da sostituire
    parsePlaceholders(copy){

      Object.keys(this.vouchersValidity).forEach(field => {
        copy = copy.replaceAll('{' + field + '}', this.vouchersValidity[field])
      });

      return copy
    },

  },
}
</script>