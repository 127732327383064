<template>
    <div id="children">
        <b-dropdown variant="transparent" id="dropdownOccupancy" ref="dropdownOccupancy" :text="children.length.toString()" data-e2e="searchFormOccupancyMenu">
            <b-dropdown-form>
                <b-form-group @submit.stop.prevent>
                    <div class="occupancy-items">
                        <div class="occupancy-item">
                            <div>
                                Adulti
                            </div>
                            <div class="d-flex align-items-center">
                                <span @click="removeAdult" class="square-icon" data-e2e="searchFormOccupancyRemoveAdults">-</span>
                                <strong>{{adults}}</strong>
                                <span @click="addAdult" class="square-icon" data-e2e="searchFormOccupancyAddAdults">+</span>
                            </div>
                        </div>

                        <div class="occupancy-item">
                            <div>
                                Bambini
                            </div>
                            <div>
                                <span @click="removeChild" class="square-icon">-</span>
                                <strong>{{children.length}}</strong>
                                <span @click="addChild" class="square-icon">+</span>
                            </div>
                        </div>  
                        
                        <div class="occupancy-item" v-if="children.length > 0">
                            <div>
                                <strong>Età dei bambini il giorno del check-in</strong>
                                <!--Specifica l’età di ogni bambino per vedere le migliori opzioni disponibili (letti, dimensioni camere...)-->
                                <div v-for="(m,index) in children" v-bind:key="index" class="occupancy-age">
                                    <span class="nowrap">Bambino {{index+1}}</span>
                                    <b-form-select :options="options" :value="m" @change="value => {setAge(value,index)}" />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-form-group>
            </b-dropdown-form>
            <template v-slot:button-content>
                <div>
                {{adults}} <span v-if="adults==1">Adulto</span><span v-else>Adulti</span>
                -
                {{children.length}} <span v-if="children.length==1">Bambino</span><span v-else>Bambini</span>
                </div>
            </template>
            <div class="occupancy-item closeButton">
                <b-button class="closeDropdown" @click="dropdownClose()" data-e2e="searchFormOccupancyConfirm">
                    Conferma
                </b-button>
            </div>
        </b-dropdown>
    </div>
</template>

<script>
export default {
    props:{
        searchMaxChildren: Number,
        searchMaxAdults: Number
    },
    computed:
    {
        children: {
            get() { return this.$store.state.occupancy.children },
            set(value) { this.mixinSendMutation('setChildren',value) }
        },
        adults: {
            get() { return this.$store.state.occupancy.adults },
            set(value) { this.mixinSendMutation('setAdults',value) }
        },
    }, 
    data() {
        return {
            //children: this.$store.state.occupancy.children,
            //adults: this.$store.state.occupancy.adults,
            options: [
                {value:0,text:'0 anni'},
                {value:1,text:'1 anno'},
                {value:2,text:'2 anni'},
                {value:3,text:'3 anni'},
                {value:4,text:'4 anni'},
                {value:5,text:'5 anni'},
                {value:6,text:'6 anni'},
                {value:7,text:'7 anni'},
                {value:8,text:'8 anni'},
                {value:9,text:'9 anni'},
                {value:10,text:'10 anni'},
                {value:11,text:'11 anni'},
                {value:12,text:'12 anni'},
                {value:13,text:'13 anni'},
                {value:14,text:'14 anni'},
                {value:15,text:'15 anni'},
                {value:16,text:'16 anni'},
                {value:17,text:'17 anni'}
            ]
        }
    },
    methods:
    {
        dropdownClose()
        {
            this.$refs.dropdownOccupancy.hide(true)
        },
        addChild: function() {
            if(this.children.length < this.searchMaxChildren)
            {
                let children = this.children
                children.push(17)
                this.$emit('occupancy', {
                    adults: this.adults,
                    children: this.children
                })
            }
        },
        removeChild: function(index) {
            if(this.children.length > 0)
            {
                let children = this.children
                children.splice(index, 1)
                this.$emit('occupancy', {
                    adults: this.adults,
                    children: this.children
                })
            }
        },
        setAge(value,index)
        {
            let children = this.children
            children[index] = value
            this.$emit('occupancy', {
                adults: this.adults,
                children: this.children
            })
        },
        addAdult: function() {
            if (this.adults < this.searchMaxAdults)
            {
                this.adults = this.adults + 1
                this.$emit('occupancy', {
                    adults: this.adults,
                    children: this.children
                })
            }
        },
        removeAdult: function() {
            if (this.adults > 1)
            {
                this.adults = this.adults - 1
                this.$emit('occupancy', {
                    adults: this.adults,
                    children: this.children
                })
            }
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    #children
    {
        .dropdown .btn.closeDropdown
        {
            display:block;
            text-align: center;
            width: 100%;
            margin-top: 20px;
        }        
        label
        {
            white-space:nowrap;
            margin-top:0.5rem;
            font-size:80%;
        }
        a
        {
            color:#fff !important;
        }
        .dropdown
        {
            background:$white;
            border-radius: $border-radius;
            display:block;
            
            & > .btn
            {
                display: flex;
                width: 100%;
                text-align: left;
                justify-content: space-between;
                align-items: center;
                font-weight: normal;
                padding-left: 0;
                box-shadow: none !important;
                max-width: 100% !important;
            }
        }
        .dropdown-menu.show
        {
            transform:translateY(-$grid-gutter-width/4) !important;
            left: 0;
            right: 0;
            top: 100% !important;
            border:0px;
            border-top:0;
            box-shadow:$box-shadow;
        }
        .occupancy-items
        {
            margin-top:$grid-gutter-width/4;
        }
        .occupancy-item
        {
            display:flex;
            justify-content: space-between;
            align-items: center;
            padding:$grid-gutter-width/4 0 $grid-gutter-width/4 $grid-gutter-width/5;
            border-top:1px solid $medium-gray;
            
            @include media-breakpoint-down(lg) {
                flex-direction: column;
                padding:$grid-gutter-width/4 0;

                & > div:first-child {
                    margin-bottom: 10px;
                }
            }

            &.closeButton
            {
                border-top:0;
                padding:0 $grid-gutter-width/4 $grid-gutter-width/4 $grid-gutter-width/4;
            }
        }
        .occupancy-age
        {
            display:flex;
            justify-content:space-between;
            align-items: center;
            font-size: 14px;
            &:last-child
            {
                margin-bottom:-0.5rem;
            }
            select
            {
                padding:0;
                max-width: 100px;
                box-shadow: none;
                font-size: 14px;
            }
        }
        .form-group
        {
            width:100%;
            margin-bottom: 0;
        }
    }
</style>