var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "31",
        height: "21",
        viewBox: "0 0 31 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M30.4789 8.62995C30.396 8.5426 30.2965 8.47296 30.1863 8.42438C30.0761 8.3758 29.9574 8.34952 29.837 8.34723C29.7953 8.34472 29.7536 8.34472 29.712 8.34723C29.4842 8.37319 29.2742 8.48261 29.1224 8.65436C28.9705 8.82612 28.8877 9.04771 28.8899 9.27692V10.3712H2.49003V1.89313C2.49539 1.64902 2.40354 1.41285 2.23478 1.23639C2.06601 1.05994 1.83411 0.957722 1.59 0.952214C1.54759 0.951764 1.50511 0.953626 1.46293 0.958073C1.23464 0.982102 1.02381 1.09098 0.872106 1.26325C0.7204 1.43552 0.638933 1.65876 0.643957 1.88825V19.3511C0.642106 19.4723 0.66424 19.5926 0.709021 19.7051C0.753801 19.8177 0.820388 19.9206 0.904943 20.0074C0.989499 20.0942 1.09031 20.1633 1.2017 20.211C1.31308 20.2587 1.43283 20.2839 1.55399 20.2852C1.7955 20.274 2.02637 20.1822 2.21 20.025C2.29918 19.9378 2.36982 19.8335 2.41776 19.7183C2.4657 19.6032 2.48988 19.4797 2.48893 19.355V16.9171H28.889V19.3521C28.8863 19.5964 28.9806 19.832 29.1512 20.0069C29.3217 20.1818 29.5547 20.2818 29.799 20.2852H29.813C30.0528 20.2857 30.2832 20.1921 30.455 20.025C30.5442 19.9378 30.615 19.8334 30.6631 19.7183C30.7112 19.6032 30.7357 19.4798 30.735 19.355V9.28229C30.7373 9.16213 30.7157 9.0426 30.6718 8.93073C30.6279 8.81886 30.5623 8.71649 30.4789 8.62995ZM28.8899 12.2203V15.0782H2.49003V12.2203H28.8899Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.59003 9.53241H11.9901C12.2345 9.53241 12.4689 9.43505 12.6419 9.26239C12.8149 9.08973 12.9125 8.85547 12.913 8.61102V6.36444C12.8958 5.80989 12.6597 5.28461 12.2565 4.9035C11.8533 4.5224 11.3157 4.31625 10.761 4.33026H5.82098C5.26634 4.31625 4.72872 4.5224 4.3255 4.9035C3.92228 5.28461 3.68627 5.80989 3.66901 6.36444V8.61102C3.66953 8.85512 3.76679 9.08929 3.93939 9.2619C4.112 9.43451 4.34592 9.53188 4.59003 9.53241ZM5.81903 6.17401H10.759C10.959 6.17401 11.066 6.27444 11.066 6.36444V7.68915H5.51202V6.36444C5.51202 6.27244 5.62003 6.17401 5.81903 6.17401Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }