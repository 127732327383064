var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "700pt",
        height: "700pt",
        version: "1.1",
        viewBox: "0 0 700 700",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "m308.62 147.93-197.23 44.023v348.68h197.23zm299.37-81.008v493.08h-515.98v-383.9l216.61-47.547v-128.55zm-19.371 15.848-260.63-59.875v517.73h260.63v-457.86z",
          },
        }),
        _c("path", { attrs: { d: "m410.75 114.46v51.07h-36.98v-51.07z" } }),
        _c("path", {
          attrs: {
            d: "m482.96 114.46v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", {
          attrs: {
            d: "m553.39 114.46v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", { attrs: { d: "m410.75 184.91v51.07h-36.98v-51.07z" } }),
        _c("path", {
          attrs: {
            d: "m482.96 184.91v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", {
          attrs: {
            d: "m553.39 184.91v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", { attrs: { d: "m410.75 253.59v51.066h-36.98v-51.066z" } }),
        _c("path", {
          attrs: {
            d: "m482.96 253.59v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", {
          attrs: {
            d: "m553.39 253.59v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", { attrs: { d: "m410.75 325.79v51.07h-36.98v-51.07z" } }),
        _c("path", {
          attrs: {
            d: "m482.96 325.79v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", {
          attrs: {
            d: "m553.39 325.79v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", { attrs: { d: "m410.75 394.46v49.309h-36.98v-49.309z" } }),
        _c("path", {
          attrs: {
            d: "m482.96 394.46v49.309h-36.98v-49.309h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", {
          attrs: {
            d: "m553.39 394.46v49.309h-36.98v-49.309h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", { attrs: { d: "m197.67 253.59v51.066h-36.98v-51.066z" } }),
        _c("path", {
          attrs: {
            d: "m268.11 253.59v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", { attrs: { d: "m197.67 325.79v51.07h-36.98v-51.07z" } }),
        _c("path", {
          attrs: {
            d: "m268.11 325.79v51.07h-36.98v-51.07h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("path", { attrs: { d: "m197.67 394.46v49.309h-36.98v-49.309z" } }),
        _c("path", {
          attrs: {
            d: "m268.11 394.46v49.309h-36.98v-49.309h36.98z",
            "fill-rule": "evenodd",
          },
        }),
        _c("use", {
          attrs: { x: "92.011719", y: "586.414062", "xlink:href": "#t" },
        }),
        _c("use", {
          attrs: { x: "98.472656", y: "586.414062", "xlink:href": "#b" },
        }),
        _c("use", {
          attrs: { x: "102.816406", y: "586.414062", "xlink:href": "#a" },
        }),
        _c("use", {
          attrs: { x: "108.789062", y: "586.414062", "xlink:href": "#d" },
        }),
        _c("use", {
          attrs: { x: "114.730469", y: "586.414062", "xlink:href": "#c" },
        }),
        _c("use", {
          attrs: { x: "118.9375", y: "586.414062", "xlink:href": "#a" },
        }),
        _c("use", {
          attrs: { x: "124.910156", y: "586.414062", "xlink:href": "#h" },
        }),
        _c("use", {
          attrs: { x: "134.28125", y: "586.414062", "xlink:href": "#j" },
        }),
        _c("use", {
          attrs: { x: "140.582031", y: "586.414062", "xlink:href": "#i" },
        }),
        _c("use", {
          attrs: { x: "149.386719", y: "586.414062", "xlink:href": "#g" },
        }),
        _c("use", {
          attrs: { x: "152.40625", y: "586.414062", "xlink:href": "#f" },
        }),
        _c("use", {
          attrs: { x: "158.671875", y: "586.414062", "xlink:href": "#h" },
        }),
        _c("use", {
          attrs: { x: "164.976562", y: "586.414062", "xlink:href": "#b" },
        }),
        _c("use", {
          attrs: { x: "169.320312", y: "586.414062", "xlink:href": "#d" },
        }),
        _c("use", {
          attrs: { x: "178.324219", y: "586.414062", "xlink:href": "#d" },
        }),
        _c("use", {
          attrs: { x: "184.269531", y: "586.414062", "xlink:href": "#f" },
        }),
        _c("use", {
          attrs: { x: "190.535156", y: "586.414062", "xlink:href": "#g" },
        }),
        _c("use", {
          attrs: { x: "193.554688", y: "586.414062", "xlink:href": "#s" },
        }),
        _c("use", {
          attrs: { x: "92.011719", y: "595.21875", "xlink:href": "#r" },
        }),
        _c("use", {
          attrs: { x: "95.84375", y: "595.21875", "xlink:href": "#b" },
        }),
        _c("use", {
          attrs: { x: "100.1875", y: "595.21875", "xlink:href": "#e" },
        }),
        _c("use", {
          attrs: { x: "106.234375", y: "595.21875", "xlink:href": "#q" },
        }),
        _c("use", {
          attrs: { x: "118.476562", y: "595.21875", "xlink:href": "#c" },
        }),
        _c("use", {
          attrs: { x: "122.683594", y: "595.21875", "xlink:href": "#p" },
        }),
        _c("use", {
          attrs: { x: "128.953125", y: "595.21875", "xlink:href": "#a" },
        }),
        _c("use", {
          attrs: { x: "137.988281", y: "595.21875", "xlink:href": "#o" },
        }),
        _c("use", {
          attrs: { x: "145.359375", y: "595.21875", "xlink:href": "#e" },
        }),
        _c("use", {
          attrs: { x: "151.40625", y: "595.21875", "xlink:href": "#n" },
        }),
        _c("use", {
          attrs: { x: "157.675781", y: "595.21875", "xlink:href": "#f" },
        }),
        _c("use", {
          attrs: { x: "167.011719", y: "595.21875", "xlink:href": "#m" },
        }),
        _c("use", {
          attrs: { x: "173.464844", y: "595.21875", "xlink:href": "#b" },
        }),
        _c("use", {
          attrs: { x: "177.804688", y: "595.21875", "xlink:href": "#e" },
        }),
        _c("use", {
          attrs: { x: "183.855469", y: "595.21875", "xlink:href": "#l" },
        }),
        _c("use", {
          attrs: { x: "186.871094", y: "595.21875", "xlink:href": "#a" },
        }),
        _c("use", {
          attrs: { x: "192.84375", y: "595.21875", "xlink:href": "#k" },
        }),
        _c("use", {
          attrs: { x: "198.0625", y: "595.21875", "xlink:href": "#c" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }