var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { class: _vm.sizeClasses }, [
        _vm.$slots.prepend || _vm.prepend
          ? _c(
              "div",
              { ref: "prependDiv", staticClass: "input-group-prepend" },
              [
                _vm._t("prepend", function () {
                  return [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(_vm._s(_vm.prepend)),
                    ]),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
        _c("input", {
          ref: "input",
          class: "form-control " + _vm.inputClass,
          attrs: {
            type: "search",
            placeholder: _vm.placeholder,
            "aria-label": _vm.placeholder,
            autocomplete: "off",
            "data-e2e": "searchFormAutocompleteDestination",
          },
          domProps: { value: _vm.inputValue },
          on: {
            focus: function ($event) {
              _vm.isFocused = true
              _vm.$parent.$emit("autocompleteFocus", true)
              _vm.setFocus(true)
            },
            blur: function ($event) {
              _vm.handleBlur
              _vm.setFocus(false)
            },
            input: function ($event) {
              return _vm.handleInput($event.target.value)
            },
          },
        }),
        _vm.$slots.append || _vm.append
          ? _c(
              "div",
              { staticClass: "input-group-append" },
              [
                _vm._t("append", function () {
                  return [
                    _c("span", { staticClass: "input-group-text" }, [
                      _vm._v(_vm._s(_vm.append)),
                    ]),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c("vue-bootstrap-typeahead-list", {
        ref: "list",
        staticClass: "vbt-autcomplete-list",
        class: {
          "vbt-autcomplete-list--hidden": !(
            _vm.isFocused && _vm.data.length > 0
          ),
        },
        attrs: {
          query: _vm.inputValue,
          data: _vm.formattedData,
          "background-variant": _vm.backgroundVariant,
          "text-variant": _vm.textVariant,
          maxMatches: _vm.maxMatches,
          minMatchingChars: _vm.minMatchingChars,
          "data-e2e": "searchFormAutocompleteSuggestions",
        },
        on: { hit: _vm.handleHit },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (slot, slotName) {
              return {
                key: slotName,
                fn: function (ref) {
                  var data = ref.data
                  var htmlText = ref.htmlText
                  return [
                    _vm._t(slotName, null, null, {
                      data: data,
                      htmlText: htmlText,
                    }),
                  ]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }