<template>
    <div id="filters">
        <b-button v-b-modal.modal-filters variant="light" class="btn-with-icon" v-if="!loading">
            <div class="btn-with-icon-inner">
                <SvgSettings />
                <span>Filtra</span>
                <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2635 1.21558L8.83 8.64915L1.39648 1.21558" stroke="#333333" stroke-width="2.25033" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </b-button>
        <b-modal id="modal-filters" size="lg" :title="'Filtra i risultati'">
            <b-form-group class="mt-4">
                <b-container fluid>
                    <b-row>
                        <b-col v-for="amenity in amenities" v-bind:key="amenity.label" cols="12" lg="3" :class="'col-'+amenity.label">
                            <b-form-group>
                                <p class="filterLabel">
                                    <span v-if="amenity.label == 'Property'">Caratteristiche</span>
                                    <span v-if="amenity.label == 'Rate'">Servizi inclusi</span>
                                    <span v-if="amenity.label == 'Refundable'">Rimborsabile</span>
                                </p>
                                <b-form-checkbox-group :id="'amenities-'+amenity.label" v-model="selectedAmenities" :name="amenity.label" stacked>
                                    <b-form-checkbox 
                                        v-for="f in amenity.filters" 
                                        v-bind:key="f.label" 
                                        :value="f.value"
                                        @change="filterChange"
                                    >
                                        {{f.text}}
                                    </b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3" :class="'col-Type'">
                            <b-form-checkbox-group id="categories" v-model="selectedCategories" name="categories" stacked>
                                <p class="filterLabel">Tipo di struttura</p>
                                <b-form-checkbox 
                                    v-for="(category,index) in categories" 
                                    v-bind:key="index" 
                                    :value="index"
                                    @change="filterChange"
                                >
                                    {{category}}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-col>
                    </b-row>
                </b-container>
            </b-form-group>
            <template v-slot:modal-footer>
                <b-button variant="secondary" @click="closeModal">Chiudi</b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
    import SvgSettings from '@/components/svg/settings'

    export default {
        props:{
            amenities: {},
            categories: {},
            loading: Boolean,
        },
        components:
        {
            SvgSettings,
        },
        computed: {
            selectedAmenities: {
                get() {
                    return this.$store.state.amenities
                },
                set(value) {
                    this.mixinSendMutation('setAmenities',value)
                }
            },
            selectedCategories: {
                get() {
                    return this.$store.state.categories
                },
                set(value) {
                    this.mixinSendMutation('setCategories',value)
                }
            }
        },
        methods: {
            filterChange()
            {
                this.$emit('filter-changed',{
                    selectedAmenities: this.selectedAmenities,
                    selectedCategories: this.selectedCategories
                })
            },
            closeModal()
            {
                /*
                this.$emit('filter-changed',{
                    selectedAmenities: this.selectedAmenities,
                    selectedCategories: this.selectedCategories
                })
                */
                this.$bvModal.hide('modal-filters')
            }
        },
        mounted()
        {
            /*
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                if(modalId == 'modal-filters')
                {
                    this.$emit('filter-changed',{
                        selectedAmenities: this.selectedAmenities,
                        selectedCategories: this.selectedCategories
                    })
                    window.console.log('close')
                }
            })
            */
        }
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    #modal-filters
    {
        #categories .custom-control-label
        {
            text-transform:lowercase;
        }
        .col-Property
        {
            order:2;
        }
        .col-Rate
        {
            order:1;
        }
        .col-Refundable
        {
            order:4;
        }
        .col-Type
        {
            order:3;
        }
       
        .filterLabel
        {
            font-weight:medium;
        }
        label
        {
            font-size: 12px;
            line-height: 120%;

        }
        .custom-control:not(:last-child) label
        {
            margin-bottom:1rem;
        }
        .custom-control-label
        {
            padding-top: 4px;
        }
        footer
        {
            justify-content:center;
        }
    }
</style>