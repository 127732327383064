var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.isFocused ? "focus" : "blur",
      attrs: {
        id: "typeahead-container",
        "data-e2e": "searchFormAutocompleteTrigger",
      },
    },
    [
      _c(
        "div",
        { staticClass: "input-icon", attrs: { id: "typeahead-back" } },
        [
          _vm.loading
            ? _c("Spinner", { attrs: { size: "sm" } })
            : _c(
                "svg",
                {
                  attrs: {
                    viewBox: "0 0 21 28",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M10.608 0.856018C7.85232 0.857872 5.21005 1.9533 3.26141 3.90175C1.31278 5.8502 0.217085 8.49236 0.214966 11.248C0.214966 16.98 6.26497 23.494 10.608 27.228C14.95 23.494 21 16.98 21 11.248C20.9979 8.49236 19.9022 5.8502 17.9535 3.90175C16.0049 1.9533 13.3626 0.857872 10.607 0.856018H10.608ZM10.607 13.825C9.83977 13.825 9.08982 13.5975 8.45192 13.1713C7.81402 12.745 7.31683 12.1392 7.02324 11.4305C6.72965 10.7217 6.65283 9.94171 6.80251 9.18925C6.95218 8.4368 7.32162 7.74564 7.86411 7.20316C8.40659 6.66067 9.09777 6.29123 9.85022 6.14156C10.6027 5.99188 11.3826 6.0687 12.0914 6.36229C12.8002 6.65588 13.406 7.15306 13.8322 7.79095C14.2585 8.42885 14.486 9.17882 14.486 9.94601C14.4857 10.9747 14.0769 11.9612 13.3495 12.6886C12.6222 13.416 11.6357 13.8247 10.607 13.825Z",
                      fill: "#0071BC",
                    },
                  }),
                ]
              ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-with-icon" },
        [
          _c("vue-bootstrap-typeahead", {
            key: _vm.geo.label,
            attrs: {
              inputClass: "autocomplete",
              placeholder:
                _vm.geo.label == "" ? "Inserisci una località" : _vm.geo.label,
              data: _vm.suggestions,
              serializer: function (s) {
                return s.label
              },
              maxMatches: _vm.maxMatches,
              minMatchingChars: _vm.minMatchingChars,
            },
            on: {
              hit: function ($event) {
                return _vm.getGeocode($event)
              },
              blur: function ($event) {
                return _vm.setFocus(false)
              },
              focus: function ($event) {
                return _vm.setFocus(true)
              },
            },
            model: {
              value: _vm.addressSearch,
              callback: function ($$v) {
                _vm.addressSearch = $$v
              },
              expression: "addressSearch",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }