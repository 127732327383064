var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nightsSelect" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c("font-awesome-icon", { attrs: { icon: "moon" } }),
        _vm._v(
          "\n        " + _vm._s(_vm.nights == 1 ? "Notte" : "Notti") + "\n    "
        ),
      ],
      1
    ),
    _c("div", { staticClass: "nightsSelectInput" }, [
      _c(
        "div",
        { staticClass: "nightsSelectInput--remove" },
        [
          _c(
            "b-button",
            {
              attrs: { size: "sm" },
              on: {
                click: function ($event) {
                  return _vm.remove()
                },
              },
            },
            [_vm._v("-")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "nightsSelectInput--input" }, [
        _c(
          "div",
          { staticClass: "number" },
          [
            _c("VNumber", {
              attrs: { speed: 250 },
              model: {
                value: _vm.nights,
                callback: function ($$v) {
                  _vm.nights = $$v
                },
                expression: "nights",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "nightsSelectInput--add" },
        [
          _c(
            "b-button",
            {
              attrs: { size: "sm" },
              on: {
                click: function ($event) {
                  return _vm.add()
                },
              },
            },
            [_vm._v("+")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }