var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "700pt",
        height: "700pt",
        version: "1.1",
        viewBox: "0 0 700 700",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "m578.97 257.59h-2.168v-86.777c0-41.691-33.922-75.617-75.613-75.617h-302.38c-41.691 0-75.613 33.926-75.613 75.617v86.777h-2.1602c-20.418 0-37.031 16.336-37.031 36.418l-0.003906 170.79h100.8v-13.988c0-10.812 8.7969-19.609 19.617-19.609h291.18c10.82 0 19.609 8.7969 19.609 19.609l0.003906 13.988h100.8l-0.003906-170.79c-0.003906-20.082-16.617-36.418-37.035-36.418zm-438.97-86.777c0-32.43 26.383-58.816 58.812-58.816h302.38c32.426 0 58.809 26.387 58.809 58.816v86.777h-16.801v-2.7812c0-35.516-28.895-64.414-64.41-64.414h-55.977c-35.516 0-64.414 28.898-64.414 64.414v2.7812h-16.801v-2.7812c0.003906-35.516-28.898-64.414-64.41-64.414h-55.973c-35.512 0-64.414 28.898-64.414 64.414v2.7812h-16.801zm386.4 86.777h-151.2v-2.7812c0-26.254 21.359-47.609 47.609-47.609h55.977c26.254 0 47.609 21.352 47.609 47.609zm-201.6 0h-151.2v-2.7812c0-26.254 21.352-47.609 47.609-47.609h55.973c26.254 0 47.609 21.352 47.609 47.609v2.7812zm-203.76 16.801h457.93c11.156 0 20.234 8.7969 20.234 19.617v75.582h-498.4l-0.007813-75.582c0-10.82 9.082-19.617 20.238-19.617zm410.85 173.61c-1.4336-18.77-17.168-33.602-36.301-33.602h-291.18c-19.137 0-34.871 14.828-36.305 33.602h-67.305v-61.605h498.4v61.605z",
          },
        }),
        _c("use", { attrs: { x: "70", y: "644", "xlink:href": "#v" } }),
        _c("use", { attrs: { x: "90.550781", y: "644", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "104.359375", y: "644", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "123.347656", y: "644", "xlink:href": "#g" } }),
        _c("use", { attrs: { x: "142.242188", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "155.628906", y: "644", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "174.617188", y: "644", "xlink:href": "#k" } }),
        _c("use", { attrs: { x: "204.410156", y: "644", "xlink:href": "#j" } }),
        _c("use", { attrs: { x: "224.453125", y: "644", "xlink:href": "#i" } }),
        _c("use", { attrs: { x: "252.453125", y: "644", "xlink:href": "#f" } }),
        _c("use", { attrs: { x: "275.882812", y: "644", "xlink:href": "#e" } }),
        _c("use", { attrs: { x: "285.484375", y: "644", "xlink:href": "#u" } }),
        _c("use", { attrs: { x: "304.101562", y: "644", "xlink:href": "#e" } }),
        _c("use", { attrs: { x: "313.703125", y: "644", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "327.085938", y: "644", "xlink:href": "#g" } }),
        _c("use", { attrs: { x: "355.726562", y: "644", "xlink:href": "#t" } }),
        _c("use", { attrs: { x: "377.425781", y: "644", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "396.664062", y: "644", "xlink:href": "#s" } }),
        _c("use", { attrs: { x: "412.960938", y: "644", "xlink:href": "#e" } }),
        _c("use", { attrs: { x: "422.554688", y: "644", "xlink:href": "#h" } }),
        _c("use", { attrs: { x: "70", y: "672", "xlink:href": "#r" } }),
        _c("use", { attrs: { x: "82.183594", y: "672", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "95.992188", y: "672", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "115.226562", y: "672", "xlink:href": "#q" } }),
        _c("use", { attrs: { x: "154.152344", y: "672", "xlink:href": "#a" } }),
        _c("use", { attrs: { x: "167.535156", y: "672", "xlink:href": "#p" } }),
        _c("use", { attrs: { x: "187.46875", y: "672", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "216.207031", y: "672", "xlink:href": "#f" } }),
        _c("use", { attrs: { x: "239.640625", y: "672", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "258.878906", y: "672", "xlink:href": "#o" } }),
        _c("use", { attrs: { x: "278.8125", y: "672", "xlink:href": "#h" } }),
        _c("use", { attrs: { x: "308.492188", y: "672", "xlink:href": "#n" } }),
        _c("use", { attrs: { x: "329.015625", y: "672", "xlink:href": "#d" } }),
        _c("use", { attrs: { x: "342.820312", y: "672", "xlink:href": "#c" } }),
        _c("use", { attrs: { x: "362.058594", y: "672", "xlink:href": "#m" } }),
        _c("use", { attrs: { x: "371.65625", y: "672", "xlink:href": "#b" } }),
        _c("use", { attrs: { x: "390.648438", y: "672", "xlink:href": "#l" } }),
        _c("use", { attrs: { x: "407.242188", y: "672", "xlink:href": "#a" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }